<template>
    <div class="row">
        <div class="col-12 mb-3 mt-5">
            <b-card header="Claim Record">
                <div class="pr-3 pt-3 pb-3 pl-3">
                    <b-row>
                        <b-col cols="3">
                            <v-date-picker
                                v-model="dateRange"
                                :model-config="modelConfig"
                                timezone="UTC"
                                is-range
                                is24hr
                                @input="getClaimRecord(true)"
                            >
                                <template v-slot="{ inputValue, togglePopover }">
                                    <b-form-group>
                                        <b-input-group v-on:click="togglePopover">
                                            <b-input-group-prepend is-text>
                                                <feather-icon icon="CalendarIcon" />
                                            </b-input-group-prepend>
                                            <b-form-input :value="dateToString(inputValue)" @keypress.prevent />
                                        </b-input-group>
                                    </b-form-group>
                                </template>
                            </v-date-picker>
                        </b-col>

                        <b-col cols="3">
                            <v-select
                                id="claimStatus"
                                label="text"
                                multiple
                                taggable
                                push-tags
                                :clearable="true"
                                :reduce="(text) => text.value"
                                placeholder="Status"
                                v-model="queryCondition.statusArray"
                                :options="claimStatusOptions"
                            />
                        </b-col>

                        <b-col cols="3">
                            <v-select
                                id="creditName"
                                label="text"
                                :clearable="true"
                                :reduce="(text) => text.value"
                                placeholder="Credit Type"
                                v-model="queryCondition.creditType"
                                :options="creditNameOptions"
                            />
                        </b-col>
                    </b-row>

                    <b-row class="mb-3">
                        <b-col cols="6">
                            <b-form-input
                                id="wallet address"
                                label="text"
                                :clearable="true"
                                placeholder="wallet address"
                                v-model="queryCondition.walletAddress"
                            />
                        </b-col>

                        <b-col cols="3">
                            <b-form-input id="sign id" label="text" :clearable="true" placeholder="sign id" v-model="queryCondition.signId" />
                        </b-col>
                    </b-row>

                    <b-row class="mb-3">
                        <b-col cols="3">
                            <b-form-input placeholder="User id" v-model="queryCondition.userId" />
                        </b-col>
                        <b-col cols="3">
                            <v-select
                                id="chainType"
                                label="text"
                                multiple
                                taggable
                                push-tags
                                :clearable="true"
                                :reduce="(text) => text.value"
                                placeholder="Chain Type"
                                v-model="queryCondition.chainTypeArray"
                                :options="chainTypeOptions"
                            />
                        </b-col>
                        <b-col cols="1">
                            <b-button variant="secondary" @click="getClaimRecord(true)"><feather-icon icon="RotateCcwIcon" size="15" /></b-button>
                        </b-col>
                    </b-row>

                    <b-overlay :show="table_show_overlay" rounded="sm">
                        <vue-good-table
                            ref="claim_record_table"
                            @on-page-change="onPageChange"
                            @on-per-page-change="onPerPageChange"
                            @on-selected-rows-change="selectionChanged"
                            :totalRows="totalRows"
                            :columns="columns"
                            :rows="row_data"
                            :sort-options="{ enabled: false }"
                            :select-options="{
                                enabled: false,
                            }"
                            :pagination-options="{
                                enabled: true,
                                mode: 'pages',
                                perPage: 10,
                                perPageDropdown: [5, 10, 20, 50, 100, 250, 500, 1000, 10000],
                                setCurrentPage: 1,
                            }"
                        >
                            <template slot="table-row" slot-scope="props">
                                <!-- Column: Common -->
                                <span v-if="props.column.field === 'to_sign_amount'">
                                    {{ $tools.tokenAmountParser.parserToMoneyFormat(props.row[props.column.field], 18) }}
                                </span>

                                <span v-else-if="props.column.field === 'created_unixtime'">
                                    {{ moment.unix(props.row[props.column.field]).utc().format("YYYY-MM-DD HH:mm:ss") }}
                                </span>

                                <span v-else-if="props.column.field === 'status'">
                                    <b-badge v-if="props.row['status'] === 'onchain'" pill variant="primary" class="mr-3">{{
                                        props.row["status"]
                                    }}</b-badge>
                                    <b-badge v-else-if="props.row['status'] === 'received'" pill variant="success" class="mr-3">{{
                                        props.row["status"]
                                    }}</b-badge>
                                </span>

                                <span v-else>
                                    {{ props.row[props.column.field] }}
                                </span>
                            </template>
                        </vue-good-table>
                    </b-overlay>
                </div>
            </b-card>
        </div>
    </div>
</template>

<script>
import {
    BCard,
    BCardFooter,
    BRow,
    BCol,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BButton,
    BOverlay,
    BBadge,
    BFormGroup,
    BFormInvalidFeedback,
    BAlert,
    BForm,
    BFormFile,
    BFormTags,
    BFormTextarea,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    VBTooltip,
    BTab,
    BTabs,
    BCardText,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import vSelect from "vue-select";
import VueQr from "vue-qr";
import moment from "moment";
import VDatePicker from "v-calendar/lib/components/date-picker.umd";
const BN = require("bn.js");

export default {
    components: {
        BCard,
        BCardFooter,
        BRow,
        BCol,
        BInputGroup,
        BFormInput,
        BInputGroupPrepend,
        BInputGroupAppend,
        BButton,
        BOverlay,
        BBadge,
        BFormGroup,
        BFormInvalidFeedback,
        BAlert,
        BForm,
        BFormFile,
        BFormTags,
        BFormTextarea,
        VueGoodTable,
        vSelect,
        BTab,
        BTabs,
        BCardText,

        BMedia,
        BMediaAside,
        BAvatar,
        BMediaBody,
        VBTooltip,

        VueQr,
        VDatePicker,
    },

    methods: {
        log(...arg) {
            return console.log(...arg);
        },

        dateToString(inputValue) {
            return inputValue.start + " - " + inputValue.end;
        },

        onPageChange(params) {
            this.limit = params.currentPerPage;
            this.offset = (params.currentPage - 1) * params.currentPerPage;
            this.getClaimRecord(true);
        },
        onPerPageChange(params) {
            this.limit = params.currentPerPage;
            this.offset = (params.currentPage - 1) * params.currentPerPage;
            this.getClaimRecord(true);
        },

        selectionChanged(params) {
            let selectedRows = params.selectedRows;

            let totalAmount = new BN("0", 10);
            for (let i = 0; i < selectedRows.length; i++) {
                let rowAmount = new BN(selectedRows[i].amount, 10);
                totalAmount = totalAmount.add(rowAmount);
                console.log(totalAmount);
            }
            this.selectedAmount = totalAmount.toString(10);
        },

        async getClaimRecord(fromDb) {
            if (this.pageMounted === false) {
                return;
            }
            if (this.isQueryingClaimRecord === true) {
                return;
            }

            let startDate = moment(this.dateRange.start).utc().format("YYYY-MM-DD");
            let endDate = moment(this.dateRange.end).utc().format("YYYY-MM-DD");

            let statusArray =
                this.queryCondition.statusArray === null || this.queryCondition.statusArray.length === 0 ? null : this.queryCondition.statusArray;
            let chainTypeArray =
                this.queryCondition.chainTypeArray === null || this.queryCondition.chainTypeArray.length === 0 ? null : this.queryCondition.chainTypeArray;
            let creditType = this.queryCondition.creditType;

            let userId = parseInt(this.queryCondition.userId);
            if (userId === null || isNaN(userId) || userId <= 0) {
                userId = null;
            }

            let walletAddress = this.queryCondition.walletAddress === "" ? null : this.queryCondition.walletAddress;
            let signId = this.queryCondition.signId === "" ? null : this.queryCondition.signId;

            this.table_show_overlay = true;
            this.isQueryingClaimRecord = true;
            // query(id, toChainType, statusArray, userId, creditType, toAddress,signId, signHex, startDate, endDate, limit, offset, fromDb, token)
            let resp = await this.$api.claim.query(
                null,
                chainTypeArray,
                statusArray,
                userId,
                creditType,
                walletAddress,
                signId,
                null,
                startDate,
                endDate,
                this.limit,
                this.offset,
                fromDb,
                this.$store.state.auth.my_web_token
            );
            this.isQueryingClaimRecord = false;
            this.table_show_overlay = false;

            if (resp.err !== null) {
                this.$bvToast.toast(resp.err, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            if (resp.result.meta_status < 0) {
                this.$bvToast.toast(resp.result.meta_message, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            this.row_data = resp.result.data;
            this.totalRows = resp.result.total_count;
        },
    },

    mounted() {
        this.pageMounted = true;
        // this.pageDataReload();
        this.getClaimRecord(true);
    },
    data() {
        return {
            moment: moment,
            isQueryingClaimRecord: false,
            pageMounted: false,

            vsecs: 0,
            columns: [
                {
                    label: "ID",
                    field: "id",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Date",
                    field: "created_unixtime",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "User id",
                    field: "user_id",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Credit Type",
                    field: "credit_type",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Chain Type",
                    field: "chain_type",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Status",
                    field: "status",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Sign id",
                    field: "to_sign_id",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "To Wallet Address",
                    field: "to_sign_hex_address",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Raw amount",
                    field: "raw_amount",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Sign amount",
                    field: "to_sign_amount",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Sign hex",
                    field: "sign_hex",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Contract Id",
                    field: "contract_id",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
            ],
            row_data: [],
            limit: 10,
            offset: 0,
            totalRows: 0,

            //for update and delete
            focus: {},

            table_show_overlay: true,
            modal_row_view_overlay: false,
            add_price_show_overlay: false,
            on_chain_show_overlay: false,

            claimStatusOptions: [
                { text: "onchain", value: "onchain" },
                { text: "received", value: "received" },
            ],

            chainTypeOptions: [{ text: "SepoliaETH", value: "eth_sepolia" }],

            creditNameOptions: [
                { text: "MESON_REWARD_MAIN", value: "MESON_REWARD_MAIN" },
                { text: "MESON_REWARD_TEST", value: "MESON_REWARD_TEST" },
            ],

            //toChainType,statusArray,userIdArray,fromCreditNameArray,merkleRoot
            queryCondition: {
                chainTypeArray: null,
                statusArray: null,
                creditType: null,
                userId: null,
                walletAddress: null,
                signId: null,
            },

            onChainMerkleRoot: "",

            dateRange: {
                start: new Date(new Date().valueOf() - 3600 * 24 * 365 * 1000),
                end: new Date(),
            },
            modelConfig: {
                start: {
                    timeAdjust: "00:00:00",
                },
                end: {
                    timeAdjust: "00:00:00",
                },
            },
        };
    },
};
</script>
